import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import PostExcerpt from "../components/postExcerpt"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import Pager from "../components/pager"

import { graphql } from "gatsby"

const TagPage = ({ data, pageContext, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const { tag } = pageContext
  const { edges } = data.allMarkdownRemark
  const tagHeader = `${tag}`

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title={tag} />
      <div style={{paddingBottom: rhythm(1.5)}}>
        <h1 style={{ marginTop: rhythm(1.5), marginBottom: 0 }}>
          {tagHeader}
        </h1>
        {edges.map(({ node, index }) => {
          return <PostExcerpt post={ node } key={ index } />
        })}
      </div>
      <hr style={{ marginBottom: rhythm(0.25) }} />
      <div
        style={{
          display: `block`,
          marginBottom: rhythm(1),
        }}
      >
        <small>Tag: {tag} (p. {pageContext.humanPageNumber})</small>
      </div>
      <Pager pageContext={pageContext} />
    </Layout>
  )
}

TagPage.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default TagPage

export const pageQuery = graphql`
  query($tag: String, $skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
          }
        }
      }
    }
  }
`
