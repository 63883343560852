import React from 'react';
import { Link } from 'gatsby';
import { rhythm } from "../utils/typography"

const Pager = ({ pageContext }) => {
  const { previousPagePath, nextPagePath } = pageContext;

  return (
    <nav>
      <ul
        style={{
          display: `flex`,
          flexWrap: `wrap`,
          justifyContent: `space-between`,
          listStyle: `none`,
          padding: 0,
          marginLeft: 0,
          marginBottom: rhythm(0.5)
        }}
      >
        <li>
          {previousPagePath && (
            <Link to={previousPagePath} rel="prev">
              ← Newer Posts
            </Link>
          )}
        </li>
        <li>
          {nextPagePath && (
            <Link to={nextPagePath} rel="next">
              Older Posts →
            </Link>
          )}
        </li>
      </ul>
    </nav>
  );
};

export default Pager;
